import { DatePicker, Form, Input, Spin, Table, notification, Button, Popconfirm, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import { ReloadOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { fetchUserMortgageBrokersAsync, selectMortgageBrokers, selectMortgageBrokersLoading } from '../../container/reducers/slices/Users/MortgageBrokerSlice';
import { ChannelPartnerType, UserStatus } from '../../components/common/userTypes';
import { ApproveMortgageBroker } from '../../container/actions/ChannelPartner/getChannelPartner.action';
import ModalAddChannelPartner from '../../utils/ModalAddChannelPartner';

const UserMortgageBroker = () => {
  const [filterForm] = Form.useForm();
  const dispatch = useDispatch();

  const [filter, setFilter] = useState({
    startDate: '',
    endDate: '',
    searchSlug: '',
  });

  const loading = useSelector(selectMortgageBrokersLoading);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [Loading, setLoading] = useState(false);

  const mortgageBrokerData = useSelector(selectMortgageBrokers);

  const navigate = useNavigate();

  useEffect(() => {
    try {
      dispatch(fetchUserMortgageBrokersAsync({ ...filter }));
    } catch (error) {
      notification.error({
        message: 'Failure',
        description: error.message || 'Something went wrong!',
      });
    }
  }, [filter, dispatch]);

  const startDateChange = (value) => {
    if (value && dayjs(value).isValid && dayjs(filter.endDate) < dayjs(value)) {
      notification.error({
        message: 'Failure',
        description: 'Start Date should be greater then End Date.',
      });
      filterForm.setFieldsValue({ startDate: '' });
      return;
    }
    setFilter({ ...filter, startDate: value && value !== null ? dayjs(value).format('YYYY-MM-DD') : '' });
  };

  const endDateChange = (value) => {
    if (value && dayjs(value).isValid && dayjs(filter.startDate) > dayjs(value)) {
      notification.error({
        message: 'Failure',
        description: 'Start Date should be greater then End Date.',
      });
      filterForm.setFieldsValue({ endDate: '' });
      return;
    }
    setFilter({ ...filter, endDate: value && value !== null ? dayjs(value).format('YYYY-MM-DD') : '' });
  };

  const SearchSlug = debounce((e) => {
    const values = e.target.value.trim();
    setFilter({ ...filter, searchSlug: values });
  }, 300);

  const handleView = (record) => {
    navigate(`/user/mortgage-broker/detail/${record.id}`);
  };

  const handleApproveMortgageBroker = (id) => {
    setLoading(true);
    dispatch(ApproveMortgageBroker(id)).then((res) => {
      if (res.success === true) {
        notification.success({
          message: 'Success',
          description: res?.message,
        });

        setLoading(false);
        setFilter({ ...filter });
      } else {
        notification.error({
          message: 'Failure',
          description: res?.message || 'Something went wrong!',
        });
        setLoading(false);
      }
    });
  };

  const MortgageBrokerColumn = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <span className="userColumnName" onClick={() => handleView(record)}>
          {text}
        </span>
      ),
    },
    {
      title: 'User Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone_number',
      key: 'phone_number',
      render: (text) => text || '-',
    },
    {
      title: 'Company Name',
      dataIndex: 'company_name',
      key: 'company_name',
      render: (_, record) => {
        return record?.company_name ? record.company_name : '-';
      },
    },
    {
      title: 'Total Clients',
      dataIndex: 'client_count',
      key: 'client_count',
    },
    {
      title: 'CRN',
      dataIndex: 'mortgage_broker_uni_number',
      key: 'mortgage_broker_uni_number',
      render: (text) => {
        return text || '-';
      },
    },
    {
      title: 'Action',
      dataIndex: 'approve',
      key: 'approve',
      render: (_text, record) => {
        if (record.channel_partner_type === ChannelPartnerType.MORTGAGE_BROKER && record.mortgage_broker_status === UserStatus.PENDING) {
          return (
            <Popconfirm title="Are you sure you want to Approve?" trigger="click" placement="topRight" onConfirm={() => handleApproveMortgageBroker(record.id)}>
              <Button type="primary">Approve</Button>
            </Popconfirm>
          );
        } else if (record.channel_partner_type === ChannelPartnerType.MORTGAGE_BROKER && record.mortgage_broker_status === UserStatus.ACTIVE) {
          return <Tag color="green">Approved</Tag>;
        } else {
          return '-';
        }
      },
    },
  ];

  const resetFilter = () => {
    filterForm.resetFields();
    setFilter({
      startDate: '',
      endDate: '',
      searchSlug: '',
    });
  };

  return (
    <>
      <div className="d-flex gap-3 align-items-center flex-sm-column loans-header-responsive flex-md-row flex-lg-row  justify-content-between">
        <div>
          <div className="text-center text-sm-start mb-sm-0 mb-3 mb-sm-0">
            <h5 className="m-0">Mortgage Brokers </h5>
          </div>
        </div>
        <div className="date_picker reset-width-100">
          <Form form={filterForm}>
            <div className="d-flex  loans-header-responsive justify-content-between align-items-center gap-3">
              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0">
                  <Form.Item name="Search" className="p-1 m-0">
                    <Input onChange={(e) => SearchSlug(e)} style={{ width: '100%', background: 'transparent' }} placeholder="Search" />
                  </Form.Item>
                </div>
              </div>
              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0">
                  <Form.Item name="startDate" className="p-0 m-0">
                    <DatePicker format="DD-MM-YYYY" onChange={startDateChange} style={{ width: '100%', background: 'transparent' }} placeholder="Start Date" />
                  </Form.Item>
                </div>
              </div>

              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0 ">
                  <Form.Item name="endDate" className="p-0 m-0">
                    <DatePicker format="DD-MM-YYYY" onChange={endDateChange} style={{ width: '100%', background: 'transparent' }} placeholder="End Date" />
                  </Form.Item>
                </div>
              </div>
              <div className="reset-width-100">
                <div className="mb-3 d-flex gap-3  mb-lg-0 ">
                  <Button icon={<ReloadOutlined />} type="primary" size="large" className="reset-width-100 d-flex align-items-center" onClick={() => resetFilter()}>
                    Reset
                  </Button>

                  <Button size="large" type="primary" className="reset-width-100" onClick={() => setIsModalOpen(true)}>
                    + Add New
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
      <Spin spinning={loading || Loading}>
        <div className="row mt-4">
          <div className="col-12">
            <div className="dashboard_list">
              <Table columns={MortgageBrokerColumn} pagination={true} dataSource={mortgageBrokerData} scroll={{ x: 'max-content' }} />
            </div>
          </div>
        </div>
      </Spin>
      <ModalAddChannelPartner isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </>
  );
};

export default UserMortgageBroker;
