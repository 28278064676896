import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import NavigationConfig from '../../configs/navigationConfig';
import { Button, Menu } from 'antd';
import './Sidebar.css';
import { HomeOutlined } from '@ant-design/icons';
import { useAuthenticator } from '@aws-amplify/ui-react';

const Sidebar = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { signOut } = useAuthenticator((context) => [context.user]);

  const getValidateClass = (currentPath) => {
    return pathname?.split('/')?.[1] === currentPath || pathname?.split('-')?.[1]?.split('/')?.[0] === currentPath ? 'active' : '';
  };

  const handleUserLogout = () => {
    signOut();
    localStorage.clear();
    navigate('/login', { replace: true });
  };

  const handleClick = (path) => {
    if (path !== 'property-settlement/pending') {
      localStorage.removeItem('settlementFilter');
    }
    if (path !== 'rent/pending') {
      localStorage.removeItem('rentPendingFilter');
    }
    if (path !== 'rent/complete') {
      localStorage.removeItem('rentCompletedFilter');
      localStorage.removeItem('rentCompletedCurrentPage');
    }
    navigate(path, { replace: true });
  };

  const menuItems = NavigationConfig.map((item) => {
    const { title, path, icon } = item;

    if (title === 'Users') {
      return {
        key: 'users',
        className: `${getValidateClass('user')} minimizedSideBarFirst`,
        label: 'Users',
        icon: icon,
        children: [
          {
            key: 'users-group',
            type: 'group',
            label: 'Users',
            children: [
              {
                key: '/user/property-investor',
                label: 'Property Investor',
                className: getValidateClass('/user/property-investor'),
                onClick: () => handleClick(`/user/property-investor`),
              },
              {
                key: '/user/tax-accountant',
                label: 'Tax Accountant',
                className: getValidateClass('/user/tax-accountant'),
                onClick: () => handleClick(`/user/tax-accountant`),
              },
              {
                key: '/user/channel-partner',
                label: 'Channel Partner',
                className: getValidateClass('/user/channel-partner'),
                onClick: () => handleClick(`/user/channel-partner`),
              },
              {
                key: '/user/mortgage-broker',
                label: 'Mortgage Broker',
                className: getValidateClass('/user/mortgage-broker'),
                onClick: () => handleClick(`/user/mortgage-broker`),
              },
              {
                key: '/user/unconfirmed-users',
                label: 'Unconfirmed Users',
                className: getValidateClass('/user/unconfirmed-users'),
                onClick: () => handleClick(`/user/unconfirmed-users`),
              },
              {
                key: '/user/mobile-unverified-users',
                label: 'Mobile Unverified',
                className: getValidateClass('/user/mobile-unverified-users'),
                onClick: () => handleClick(`/user/mobile-unverified-users`),
              },
              {
                key: '/user/managed-users',
                label: 'Managed Users',
                className: getValidateClass('/user/managed-users'),
                onClick: () => handleClick(`/user/managed-users`),
              },
              {
                key: '/user/not-registered-users',
                label: 'Not Registered',
                className: getValidateClass('/user/not-registered-users'),
                onClick: () => handleClick(`/user/not-registered-users`),
              },
            ],
          },
        ],
      };
    }

    if (title === 'Rent Summary') {
      return {
        key: 'rent-summary',
        label: 'Rent Summary',
        icon: icon,
        className: `${getValidateClass('rent')} ${getValidateClass('end-of-year')} minimizedSideBarFirst `,
        children: [
          {
            key: 'rent-summary-group',
            type: 'group',
            label: 'Rent Summary',
            children: [
              {
                key: '/rent/pending',
                label: 'Pending',
                className: getValidateClass('/rent/pending'),
                onClick: () => handleClick(`/rent/pending`),
              },
              {
                key: '/rent/complete',
                label: 'Completed',
                className: getValidateClass('/rent/complete'),
                onClick: () => handleClick(`/rent/complete`),
              },
              {
                key: '/end-of-year/pending',
                label: 'EOFY',
                className: getValidateClass('/end-of-year/pending'),
                onClick: () => handleClick(`/end-of-year/pending`),
              },
            ],
          },
        ],
      };
    }

    if (title === 'Depreciation') {
      return {
        key: 'depreciation',
        label: title,
        className: `${getValidateClass('depreciation')} minimizedSideBarFirst`,
        title: title,
        icon: icon,
        children: [
          {
            key: 'depreciation-group',
            type: 'group',
            label: 'Depreciation',
            children: [
              {
                key: '/depreciation/pending',
                label: 'Pending',
                className: getValidateClass('/depreciation/pending'),
                onClick: () => handleClick(`/depreciation/pending`),
              },
              {
                key: '/depreciation/complete',
                label: 'Completed',
                className: getValidateClass('/depreciation/complete'),
                onClick: () => handleClick(`/depreciation/complete`),
              },
            ],
          },
        ],
      };
    }

    if (title === 'Property Settlement') {
      return {
        key: 'property-settlement',
        label: title,
        className: `${getValidateClass('property-settlement')} minimizedSideBarFirst`,
        icon: icon,
        children: [
          {
            key: 'property-settlement-group',
            type: 'group',
            label: 'Property Settlement',
            children: [
              {
                key: '/property-settlement/pending',
                label: 'Pending',
                className: getValidateClass('/property-settlement/pending'),
                onClick: () => handleClick(`/property-settlement/pending`),
              },
              {
                key: '/property-settlement/complete',
                label: 'Completed',
                className: getValidateClass('/property-settlement/complete'),
                onClick: () => handleClick(`/property-settlement/complete`),
              },
            ],
          },
        ],
      };
    }

    if (title === 'Notification') {
      return {
        key: 'notification',
        label: title,
        icon: icon,
        className: `${getValidateClass('templates') || getValidateClass('notification')} minimizedSideBarFirst`,
        children: [
          {
            key: 'notification-group',
            type: 'group',
            label: 'Notification',
            children: [
              {
                key: '/templates',
                label: 'Template',
                className: getValidateClass('/templates'),
                onClick: () => handleClick(`/templates`),
              },
              {
                key: '/notification',
                label: 'Schedule Notification',
                className: getValidateClass('/notification'),
                onClick: () => handleClick(`/notification`),
              },
            ],
          },
        ],
      };
    }

    if (title === 'Logout') {
      return {
        key: 'logout',
        label: title,
        icon: icon,
        onClick: handleUserLogout,
      };
    }

    if (title === 'Coupon') {
      return {
        key: 'coupon',
        label: title,
        icon: icon,
        className: `${getValidateClass('coupons')} `,
        onClick: () => handleClick('/coupons'),
      };
    }

    return {
      key: path,
      label: title,
      icon: icon,
      className: `${getValidateClass(path)}`,
      onClick: () => handleClick(path),
    };
  });

  return (
    <nav id="sidebar" className="sidebar-wrapper">
      <div style={{ flex: '1' }}>
        <div className="sidebar-brand subMenuTaxAccountant minimizedSideBarFirst border-bottom p-2" style={{ textAlign: 'center' }}>
          <Link to="/dashboard" className="logo-icon">
            <HomeOutlined className="logo-light-mode scale" />
          </Link>
        </div>
        <Menu
          mode="inline"
          inlineCollapsed={false}
          className="sidebar-menu side_wrapper minimizedSideBarFirst subMenuTaxAccountant"
          // defaultOpenKeys={openKeys}
          selectedKeys={[pathname]}
          items={menuItems}
        />
      </div>
    </nav>
  );
};

export default Sidebar;
