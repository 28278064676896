import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { Layout } from 'antd';
import Header from './Header';
import Sidebar from './Sidebar';
const { Sider } = Layout;

const MainLayout = () => {
  const { pathname } = useLocation();

  if (pathname === '/') {
    return <Navigate to="/dashboard" />;
  }

  return (
    <div className="page-wrapper landrick-theme toggled" id="side__bar">
      <Sider trigger={null} width={80} className="bg-white" collapsible collapsed={true}>
        <Sidebar />
      </Sider>

      <main className="page-content bg-light">
        <Header />

        <div className="container-fluid mt-5 pt-5 ps-4">
          <Outlet />
        </div>
      </main>
    </div>
  );
};

export default MainLayout;
