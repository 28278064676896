import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MainLayout from '../components/layout';
import Dashboard from '../pages/dashboard';
import JunkEmail from '../pages/dashboard/JunkEmail';
import DepreciationCompleteSchedule from '../pages/depreciation/depreciationCompleteSchedule';
import DepreciationSchedule from '../pages/depreciation/depreciationSchedule';
import Login from '../pages/login';
import Verify from '../pages/login/verify';
import Settlement from '../pages/settlement';
import SettlementDetails from '../pages/settlement/settlementDetails';
import Valuation from '../pages/Valuation';
import ProtectedRoutes from './ProtectedRoutes';
import PublicRoutes from './PublicRoutes';
import ChannelPartner from '../pages/ChannelPartner';
import RentalSummary from '../pages/RentSummary/ReviewRentsummray';
import Loan from '../pages/Loan';
import UnconfirmedUsers from '../pages/UnconfirmedUsers';
import EndOfYear from '../pages/EndOfYear/index';
import EndOfYearSchedule from '../pages/EndOfYear/EndOfYearSchedule';
import Templates from '../pages/Notification/Templates';
import Notification from '../pages/Notification/Notification';
import UserOverview from '../pages/BasiqMultipleAccounts';
import DepreciationPending from '../pages/depreciation/DepreciationPending';
import DepreciationCompleted from '../pages/depreciation/DepreciationCompleted';
import SettlementPending from '../pages/settlement/SettlementPending';
import SettlementCompleted from '../pages/settlement/SettlementCompleted';
import RentPending from '../pages/RentSummary/RentPending';
import RentCompleted from '../pages/RentSummary/RentCompleted';
import UserPropertyInvestor from '../pages/Users/UserPropertyInvestor';
import UserTaxAccountant from '../pages/Users/UserTaxAccountant';
import Overview from '../pages/BasiqMultipleAccounts/Overview';
import MobileUnverified from '../pages/MobileUnverified';
import UserMortgageBroker from '../pages/Users/UserMortgageBroker';
import UserManageClients from '../pages/Users/UserManageClients';
import NotRegisteredUsers from '../pages/Users/NotRegisteredUsers';
import Coupon from '../pages/BasiqMultipleAccounts/Coupon';

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* Public Routes */}
        <Route path="*" element={<>Not Found</>} />
        <Route
          path="/login"
          element={
            <PublicRoutes>
              <Login />
            </PublicRoutes>
          }
        />
        <Route
          path="/Verify/:type"
          element={
            <PublicRoutes>
              <Verify />
            </PublicRoutes>
          }
        />

        {/* Protected Routes */}
        <Route
          path="/"
          element={
            <ProtectedRoutes>
              <MainLayout />
            </ProtectedRoutes>
          }
        >
          <Route
            path="/dashboard"
            element={
              <ProtectedRoutes>
                <Dashboard />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/dashboard/junk-emails"
            element={
              <ProtectedRoutes>
                <JunkEmail />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/depreciation/pending"
            element={
              <ProtectedRoutes>
                <DepreciationPending />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/depreciation/complete"
            element={
              <ProtectedRoutes>
                <DepreciationCompleted />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/depreciation/pending/:id"
            element={
              <ProtectedRoutes>
                <DepreciationSchedule />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/depreciation/complete/:id"
            element={
              <ProtectedRoutes>
                <DepreciationCompleteSchedule />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/property-settlement/:status"
            element={
              <ProtectedRoutes>
                <Settlement />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/property-settlement/pending"
            element={
              <ProtectedRoutes>
                <SettlementPending />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/property-settlement/complete"
            element={
              <ProtectedRoutes>
                <SettlementCompleted />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/property-settlement/:status/:id"
            element={
              <ProtectedRoutes>
                <SettlementDetails />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/property-valuation"
            element={
              <ProtectedRoutes>
                <Valuation />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/rent/pending"
            element={
              <ProtectedRoutes>
                <RentPending />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/rent/complete"
            element={
              <ProtectedRoutes>
                <RentCompleted />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/review-rent/:id"
            element={
              <ProtectedRoutes>
                <RentalSummary />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/user/property-investor"
            element={
              <ProtectedRoutes>
                <UserPropertyInvestor />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/user/tax-accountant"
            element={
              <ProtectedRoutes>
                <UserTaxAccountant />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/user/mortgage-broker"
            element={
              <ProtectedRoutes>
                <UserMortgageBroker />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/user/channel-partner"
            element={
              <ProtectedRoutes>
                <ChannelPartner />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/user/managed-users"
            element={
              <ProtectedRoutes>
                <UserManageClients />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/user/not-registered-users"
            element={
              <ProtectedRoutes>
                <NotRegisteredUsers />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/loans"
            element={
              <ProtectedRoutes>
                <Loan />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/user/unconfirmed-users"
            element={
              <ProtectedRoutes>
                <UnconfirmedUsers />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/user/mobile-unverified-users"
            element={
              <ProtectedRoutes>
                <MobileUnverified />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/templates"
            element={
              <ProtectedRoutes>
                <Templates />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/notification"
            element={
              <ProtectedRoutes>
                <Notification />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/end-of-year/:status"
            element={
              <ProtectedRoutes>
                <EndOfYear />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/user/property-investor/detail/:id"
            element={
              <ProtectedRoutes>
                <UserOverview />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/user/tax-accountant/detail/:id"
            element={
              <ProtectedRoutes>
                <Overview />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/user/mortgage-broker/detail/:id"
            element={
              <ProtectedRoutes>
                <Overview />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/end-of-year/:status/:id"
            element={
              <ProtectedRoutes>
                <EndOfYearSchedule />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/coupons"
            element={
              <ProtectedRoutes>
                <Coupon />
              </ProtectedRoutes>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
