import { Badge, Button, Input, Table } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { PencilIcon } from '../../assets/icons';
import { SearchOutlined } from '@ant-design/icons';
import { GetCompleteDepreciation } from '../../container/actions/depreciation/getCompleteDepriciation.action';
import { getPropertyTypeLabel } from '../../utils/ConstLabel';

const DepreciationCompleted = () => {
  const dispatch = useDispatch();
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const { depreciationDatacomplete, isLoading } = useSelector((state) => state.depreciationCompleteDataReducer);

  useEffect(() => {
    dispatch(GetCompleteDepreciation());
  }, [dispatch]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const getColumnCopleteSearchProps = (dataIndex, columnName) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${columnName}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} icon={<SearchOutlined />} size="small" style={{ width: 90, marginRight: 8 }}>
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: <SearchOutlined />,
    onFilter: (value, record) => {
      const dataValue = record[dataIndex];
      if (dataValue) {
        return dataValue.toString().toLowerCase().includes(value.toLowerCase());
      }
      return false;
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.input?.select(), 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? <span style={{ fontWeight: 'bold' }}>{text}</span> : text),
  });

  const tableColumnscompleted = [
    {
      title: 'User Email',
      dataIndex: 'email',
      key: 'email',
      ...getColumnCopleteSearchProps('email', 'User Email'),
      render: (_, record) => {
        return <>{record?.email}</>;
      },
    },
    {
      title: 'User Unique ID',
      dataIndex: 'virtual_email',
      key: 'virtual_email',
      ...getColumnCopleteSearchProps('virtual_email', 'User Unique ID'),
      render: (_, record) => {
        return <>{record?.virtual_email}</>;
      },
    },
  ];

  const expandedRowRender = (record) => {
    const columns = [
      {
        title: 'Street Number',
        dataIndex: 'street_number',
        key: 'name',
        width: '20%',
      },
      {
        title: 'Street Name',
        dataIndex: 'street_name',
        key: 'date',
        width: '20%',
      },
      {
        title: 'Suburb',
        dataIndex: 'suburb',
        key: 'name',
        width: '20%',
      },
      {
        title: 'Property Type',
        dataIndex: 'property_type',
        key: 'property_type',
        width: '15%',
        render: (text) => {
          return getPropertyTypeLabel(text);
        },
      },
      {
        title: 'Status',
        key: 'state',
        render: (_, record) => <>{record.depreciation === null ? <Badge status="warning" text="Pending" /> : <Badge status="success" text="Completed" />}</>,
        width: '20%',
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        width: '20%',
        // render: (_, record) => {
        //   {
        //     return record.depreciation === null ? (
        //       '-'
        //     ) : (
        //       <>
        //         <button className="btn btn-primary px-0 mt-xxl-0 mt-2">
        //           {' '}
        //           <Link to={`/depreciation/complete/${record?.depreciation?.id}`}>
        //             <PencilIcon className="img-fluid" />
        //           </Link>
        //         </button>
        //       </>
        //     );
        //   }
        // },
        render: (_, record) => {
          return record.depreciation === null ? (
            '-'
          ) : (
            <button className="btn btn-primary px-0 mt-xxl-0 mt-2">
              <Link to={`/depreciation/complete/${record?.depreciation?.id}`}>
                <PencilIcon className="img-fluid" />
              </Link>
            </button>
          );
        },
      },
    ];

    return <Table columns={columns} dataSource={record.properties} pagination={false} className="nestaed_tabel" rowKey={'id'} />;
  };

  return (
    <>
      <div className="row align-items-center">
        <div className="col-12">
          <div className="text-center text-sm-start mb-sm-0 mb-3 mb-sm-0">
            <h5 className="m-0">Depreciation Completed</h5>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <div className="dashboard_list">
            <Table
              columns={tableColumnscompleted}
              loading={isLoading}
              expandable={{
                expandedRowRender,
              }}
              rowKey={'id'}
              dataSource={depreciationDatacomplete.result}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DepreciationCompleted;
