import { Button, notification, Popconfirm, Switch, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  changeUserDiscountStatusAsync,
  deleteUserDiscountAsync,
  fetchUserDiscountAsync,
  selectUserDiscount,
  selectUserDiscountLoading,
} from '../../container/reducers/slices/UserDiscount/UserDiscountSlice';
import AddUserDiscount from './AddUserDiscount';
import { DeleteOutlined } from '@ant-design/icons';
import { CustomIcon } from '../../assets/icons';
import editIcon from '../../assets/images/edit (1).svg';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { UserStatus } from '../../components/common/userTypes';
dayjs.extend(isSameOrAfter);

export default function UserDiscount() {
  const [addOpen, setAddOpen] = useState(false);
  const [record, setRecord] = useState(null);

  const { id } = useParams();

  const dispatch = useDispatch();

  const userDiscount = useSelector(selectUserDiscount);
  const discountData = userDiscount?.result || [];
  const loading = useSelector(selectUserDiscountLoading);

  useEffect(() => {
    if (id) {
      dispatch(fetchUserDiscountAsync(id));
    }
  }, [dispatch, id]);

  const handleDelete = async (recordId) => {
    try {
      const res = await dispatch(deleteUserDiscountAsync(recordId));
      if (res?.payload?.data?.success === true) {
        notification.success({
          message: 'Success',
          description: res?.payload?.data?.message,
        });
        await dispatch(fetchUserDiscountAsync(id));
      } else {
        notification.error({
          message: 'Failure',
          description: res?.payload?.data?.message || 'Something went wrong!',
        });
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.message || 'Something went wrong!',
      });
    }
  };

  const handleEditDiscount = (record) => {
    setRecord(record);
    setAddOpen(true);
  };

  const handleCouponState = async (checked, record) => {
    let active = true;
    if (record.is_active === UserStatus.ACTIVE) {
      active = UserStatus.DELETED;
    } else {
      active = UserStatus.ACTIVE;
    }

    try {
      const res = await dispatch(changeUserDiscountStatusAsync({ userId: id, id: record.id, info: { is_active: active } }));
      if (res?.payload?.data?.success === true) {
        notification.success({
          message: 'Success',
          description: res?.payload?.data?.message,
        });
        await dispatch(fetchUserDiscountAsync(id));
      } else {
        notification.error({
          message: 'Failure',
          description: res?.payload?.data?.message || 'Something went wrong!',
        });
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.message || 'Something went wrong!',
      });
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Apply From',
      dataIndex: 'apply_from',
      key: 'apply_from',
    },
    {
      title: 'Apply To',
      dataIndex: 'apply_to',
      key: 'apply_to',
    },
    {
      title: 'Valid From',
      dataIndex: 'valid_from',
      key: 'valid_from',
    },
    {
      title: 'Valid To',
      dataIndex: 'valid_to',
      key: 'valid_to',
    },

    {
      title: 'Discount (%)',
      dataIndex: 'discount_percentage',
      key: 'discount_percentage',
      render: (text) => {
        return text ? `${text}%` : '-';
      },
    },
    {
      title: 'Amount ($)',
      dataIndex: 'discount_amount',
      key: 'discount_amount',
      render: (text) => {
        return text ? `$${text}` : '-';
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => {
        return (
          <Switch
            checkedChildren="Active"
            unCheckedChildren="Inactive"
            disabled={dayjs().isSameOrAfter(record.apply_from, 'day')}
            checked={record.is_active === UserStatus.ACTIVE}
            onChange={(checked) => handleCouponState(checked, record)}
          />
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (text, record) => {
        return dayjs().isSameOrAfter(record.apply_from, 'day') ? (
          '-'
        ) : (
          <div className="d-flex gap-3">
            <Button icon={<CustomIcon icon={editIcon} />} onClick={() => handleEditDiscount(record)} type="primary" size="large" />
            <Popconfirm title="Are you sure you want to delete?" onConfirm={() => handleDelete(record.id)} okText="Yes" cancelText="No">
              <Button
                className="Delete_btn"
                size="large"
                icon={
                  <DeleteOutlined
                    style={{
                      fontSize: 18,
                      paddingTop: 3,
                    }}
                  />
                }
                type="primary"
              />
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const handleCreate = () => {
    setAddOpen(true);
  };

  return (
    <div className="container">
      <div className="d-flex justify-content-end mb-3">
        <Button type="primary" onClick={handleCreate}>
          + Add
        </Button>
      </div>
      <Table dataSource={discountData} columns={columns} loading={loading} />

      <AddUserDiscount openAdd={addOpen} setOpenAdd={setAddOpen} id={id} record={record} setRecord={setRecord} />
    </div>
  );
}
