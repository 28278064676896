import { Form, Input, Table, Button, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { ReloadOutlined } from '@ant-design/icons';
import { getNotRegisteredUsersAsync, selectNotRegisteredUsers, selectNotRegisteredUsersLoading } from '../../container/reducers/slices/NotRegisteredUsers/NotRegisteredUsersSlice';
import { getUserTypeLabel } from '../../utils/ConstLabel';
import { ChannelPartnerType, UserTypes } from '../../components/common/userTypes';

const NotRegisteredUsers = () => {
  const [filterForm] = Form.useForm();
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({
    searchSlug: '',
    userType: null,
  });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    defaultPageSize: 10,
    showSizeChanger: true,
  });

  const data = useSelector(selectNotRegisteredUsers);
  const loading = useSelector(selectNotRegisteredUsersLoading);

  useEffect(() => {
    dispatch(getNotRegisteredUsersAsync({ ...filter, skip: (Number(pagination.current) - 1) * pagination.pageSize, limit: pagination.pageSize }));
  }, [filter, dispatch, pagination]);

  const SearchSlug = debounce((e) => {
    const values = e.target.value.trim();
    setPagination({ ...pagination, current: 1 });
    setFilter({ ...filter, searchSlug: values });
  }, 300);

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  const TaxAccountantColumn = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'User Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone_number',
      key: 'phone_number',
      render: (text) => text || '-',
    },
    {
      title: 'User type',
      dataIndex: 'user_type',
      key: 'user_type',
      render: (text, record) => {
        if (record?.channel_partner_type) {
          return getUserTypeLabel(record?.channel_partner_type);
        } else {
          return getUserTypeLabel(text);
        }
      },
    },
    {
      title: 'Admin Name',
      dataIndex: 'tax_accountant_name',
      key: 'tax_accountant_name',
      render: (text) => text || '-',
    },
  ];

  const handleUserTypeChange = (value) => {
    setPagination({ ...pagination, current: 1 });
    setFilter({ ...filter, userType: value });
  };

  const resetFilter = () => {
    filterForm.resetFields();
    setFilter({
      searchSlug: '',
      userType: null,
    });
  };

  const userTypeDropdownOptions = [
    {
      label: 'Tax Accountant',
      value: UserTypes.TAXACCOUTANT,
    },
    {
      label: 'Sub Tax Accountant',
      value: UserTypes.SUB_TAXACCOUTANT,
    },
    {
      label: 'Admin Sub Tax Accountant',
      value: UserTypes.ADMIN_SUB_TAXACCOUNTANT,
    },
    {
      label: 'Mortgage Broker',
      value: ChannelPartnerType.MORTGAGE_BROKER,
    },
    {
      label: 'Conveyancer',
      value: ChannelPartnerType.CONVEYANCER,
    },
    {
      label: 'Rental Property Manager',
      value: ChannelPartnerType.RENTAL_PROPERTY_MANAGER,
    },
    {
      label: 'Buyers Agent',
      value: ChannelPartnerType.BUYERS_AGENT,
    },
    {
      label: 'Others',
      value: ChannelPartnerType.OTHERS,
    },
  ];

  return (
    <>
      <div className="d-flex gap-3 align-items-center flex-sm-column loans-header-responsive flex-md-row flex-lg-row  justify-content-between">
        <div>
          <div className="text-center text-sm-start mb-sm-0 mb-3 mb-sm-0">
            <h5 className="m-0">Not Registered Users </h5>
          </div>
        </div>
        <div className="reset-width-100">
          <Form form={filterForm}>
            <div className="d-flex  loans-header-responsive justify-content-between align-items-center gap-3 w-100">
              <div className="reset-width-100">
                <div className="mb-2 pt-1 mb-lg-0">
                  <Form.Item name="Search" className="p-1 m-0">
                    <Input onChange={(e) => SearchSlug(e)} allowClear style={{ width: '100%', background: 'transparent' }} placeholder="Search" />
                  </Form.Item>
                </div>
              </div>
              <div className="reset-width-100">
                <div
                  className="mb-3 mb-lg-0 "
                  style={{
                    width: 220,
                  }}
                >
                  <Form.Item name="userTypeFilter" className="p-1 m-0 w-100">
                    <Select options={userTypeDropdownOptions} placeholder={<p className="text-center m-0 p-0">- - All Users - -</p>} onChange={handleUserTypeChange} allowClear />
                  </Form.Item>
                </div>
              </div>

              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0 ">
                  <Button
                    icon={<ReloadOutlined />}
                    type="primary"
                    size="middle"
                    className="reset-width-100 d-flex align-items-center"
                    onClick={() => resetFilter()}
                    style={{
                      height: 36,
                    }}
                  >
                    Reset
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <div className="dashboard_list">
            <Table
              columns={TaxAccountantColumn}
              pagination={{ ...pagination, total: data?.count }}
              dataSource={data?.users}
              scroll={{ x: 'max-content' }}
              onChange={handleTableChange}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default NotRegisteredUsers;
