import { DatePicker, Form, Input, Spin, Table, notification, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import { ReloadOutlined } from '@ant-design/icons';
import { getManageClientsUsersAsync, selectManageClientsUser, selectManageClientsUserLoading } from '../../container/reducers/slices/ManageClientsUsers/ManageClientsUsersSlice';

const UserManageClients = () => {
  const [filterForm] = Form.useForm();
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({
    startDate: '',
    endDate: '',
    searchSlug: '',
  });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    defaultPageSize: 10,
    showSizeChanger: true,
  });

  const managedUsers = useSelector(selectManageClientsUser);
  const loading = useSelector(selectManageClientsUserLoading);

  useEffect(() => {
    dispatch(getManageClientsUsersAsync({ ...filter, skip: (Number(pagination.current) - 1) * pagination.pageSize, limit: pagination.pageSize }));
  }, [filter, dispatch, pagination]);

  const startDateChange = (value) => {
    if (value && dayjs(value).isValid && dayjs(filter.endDate) < dayjs(value)) {
      notification.error({
        message: 'Failure',
        description: 'Start Date should be greater then End Date.',
      });
      filterForm.setFieldsValue({ startDate: '' });
      return;
    }
    setPagination({ ...pagination, current: 1 });
    setFilter({ ...filter, startDate: value && value !== null ? dayjs(value).format('YYYY-MM-DD') : '' });
  };

  const endDateChange = (value) => {
    if (value && dayjs(value).isValid && dayjs(filter.startDate) > dayjs(value)) {
      notification.error({
        message: 'Failure',
        description: 'Start Date should be greater then End Date.',
      });
      filterForm.setFieldsValue({ endDate: '' });
      return;
    }
    setPagination({ ...pagination, current: 1 });
    setFilter({ ...filter, endDate: value && value !== null ? dayjs(value).format('YYYY-MM-DD') : '' });
  };

  const SearchSlug = debounce((e) => {
    const values = e.target.value.trim();
    setPagination({ ...pagination, current: 1 });
    setFilter({ ...filter, searchSlug: values });
  }, 300);

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'User Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone_number',
      key: 'phone_number',
      render: (text) => text || '-',
    },
    {
      title: 'Number Of Properties',
      dataIndex: 'properties',
      key: 'properties',
    },
    {
      title: 'Tax Accountant',
      dataIndex: 'tax_accountant_name',
      key: 'tax_accountant_name',
    },
  ];

  const resetFilter = () => {
    filterForm.resetFields();
    setFilter({
      startDate: '',
      endDate: '',
      searchSlug: '',
    });
  };

  return (
    <>
      <div className="d-flex gap-3 align-items-center flex-sm-column loans-header-responsive flex-md-row flex-lg-row  justify-content-between">
        <div>
          <div className="text-center text-sm-start mb-sm-0 mb-3 mb-sm-0">
            <h5 className="m-0">Managed Users</h5>
          </div>
        </div>
        <div className="date_picker reset-width-100">
          <Form form={filterForm}>
            <div className="d-flex  loans-header-responsive justify-content-between align-items-center gap-3">
              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0">
                  <Form.Item name="Search" className="p-1 m-0">
                    <Input onChange={(e) => SearchSlug(e)} style={{ width: '100%', background: 'transparent' }} placeholder="Search" />
                  </Form.Item>
                </div>
              </div>
              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0">
                  <Form.Item name="startDate" className="p-0 m-0">
                    <DatePicker format="DD-MM-YYYY" onChange={startDateChange} style={{ width: '100%', background: 'transparent' }} placeholder="Start Date" />
                  </Form.Item>
                </div>
              </div>

              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0 ">
                  <Form.Item name="endDate" className="p-0 m-0">
                    <DatePicker format="DD-MM-YYYY" onChange={endDateChange} style={{ width: '100%', background: 'transparent' }} placeholder="End Date" />
                  </Form.Item>
                </div>
              </div>
              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0 ">
                  <Button icon={<ReloadOutlined />} type="primary" size="large" className="reset-width-100 d-flex align-items-center" onClick={() => resetFilter()}>
                    Reset
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <div className="dashboard_list">
            <Table
              columns={columns}
              pagination={{ ...pagination, total: managedUsers?.count }}
              dataSource={managedUsers?.users}
              scroll={{ x: 'max-content' }}
              onChange={handleTableChange}
              loading={loading}
            />{' '}
          </div>
        </div>
      </div>
    </>
  );
};

export default UserManageClients;
