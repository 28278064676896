import { CloseCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import React, { useMemo } from 'react';

const PreviewTemplate = ({ type, setType, open, setOpen, record, setRecord, isNotification, previewContent, setPreviewContent, subject }) => {
  const isEmail = type === 'email';
  const isSms = type === 'sms';
  const isPushNotification = type === 'pushNotification';

  const content = useMemo(() => {
    if (isEmail) {
      if (isNotification) {
        return record?.email_templete_content;
      } else {
        return previewContent || record?.content;
      }
    }
    if (isSms) {
      return previewContent || record?.sms_templete_content || record?.content;
    }
    if (isPushNotification) {
      if (isNotification) {
        return record?.push_notification_templete_content;
      } else {
        return previewContent || record?.content;
      }
    }
  }, [isEmail, isSms, isPushNotification, previewContent, isNotification, record]);

  const title = useMemo(() => {
    if (isEmail) {
      if (record !== undefined) {
        if (isNotification) {
          return `Subject: ${record?.email_templete_subject}`;
        } else if (record?.subject) {
          return `Subject: ${record.subject}`;
        }
      } else if (subject) {
        return `Subject: ${subject}`;
      } else {
        return 'Email Template Preview';
      }
    }
    if (isSms) {
      return 'SMS Template Preview';
    }
    if (isPushNotification) {
      if (record) {
        if (isNotification) {
          return `Title: ${record?.push_notification_templete_subject}`;
        } else if (record?.subject) {
          return `Title: ${record.subject}`;
        }
      } else if (subject) {
        return `Title: ${subject}`;
      } else {
        return 'Push Notification Template Preview';
      }
    }
    return '';
  }, [isEmail, isSms, isPushNotification, subject, isNotification, record]);

  const handlePreviewClose = () => {
    setPreviewContent('');
    setOpen(false);
    setType('');
    setRecord(null);
  };

  return (
    <>
      <Modal destroyOnClose footer={null} title={title} closeIcon={<CloseCircleOutlined className="modalCloseIcon" />} centered open={open} onCancel={() => handlePreviewClose()} width={1000}>
        <div className="templatePreviewParent">
          <div className="templatePreviewContent" dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      </Modal>
    </>
  );
};

export default PreviewTemplate;

PreviewTemplate.defaultProps = {
  open: false,
  setOpen: () => {},
  type: '',
  setType: () => {},
  record: undefined,
  setRecord: () => {},
  previewContent: '',
  setPreviewContent: () => {},
  subject: '',
};
