import React from 'react';
import Overview from './Overview';
import { UserOutlined, BankOutlined, LeftOutlined } from '@ant-design/icons';
import { Tabs, Button } from 'antd';
import BankDetails from './BankDetails';
import { useNavigate } from 'react-router-dom';
import './BasiqMultipleAccounts.css';

const UserOverview = () => {
  const navigate = useNavigate();

  const items = [
    {
      label: `Overview`,
      key: '1',
      children: <Overview />,
      icon: <UserOutlined />,
    },
    {
      label: `Bank Details`,
      key: '2',
      children: <BankDetails />,
      icon: <BankOutlined />,
    },
  ];

  const handleClick = () => {
    navigate('/user/property-investor');
  };

  return (
    <div className="customTab">
      <div className="d-flex justify-content-start align-items-center gap-2">
        <Button onClick={() => handleClick()} icon={<LeftOutlined />} />
        <h5 className="p-0 m-0">User Details </h5>
      </div>

      <Tabs type="card" defaultActiveKey="1" items={items} className="mt-3" />
    </div>
  );
};

export default UserOverview;
