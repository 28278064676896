import { TagsOutlined } from '@ant-design/icons';
import { DashboardIcon, DepreciationIcon, PropertySettlementIcon, PropertyValuationsIcon, RentSummaryIcon, RentSummaryNoMarginIcon, UsersIcon } from '../assets/icons';

const NavigationConfig = [
  {
    title: 'Dashboard',
    path: 'dashboard',
    icon: <DashboardIcon />,
  },
  {
    title: 'Depreciation',
    path: 'depreciation/pending',
    icon: <DepreciationIcon />,
  },
  {
    title: 'Property Settlement',
    path: 'property-settlement/pending',
    icon: <PropertySettlementIcon />,
  },
  {
    title: 'Property Valuation',
    path: 'property-valuation',
    icon: <PropertyValuationsIcon />,
  },
  {
    title: 'Rent Summary',
    path: 'rent/pending',
    icon: <RentSummaryNoMarginIcon />,
  },
  {
    title: 'Users',
    path: 'users',
    icon: <UsersIcon />,
  },
  {
    title: 'User Loans',
    path: 'loans',
    icon: <PropertyValuationsIcon />,
  },

  {
    title: 'Notification',
    path: 'notification',
    icon: <RentSummaryIcon />,
  },

  {
    title: 'Coupon',
    path: 'coupons',
    icon: (
      <TagsOutlined
      // style={{
      //   fontSize: '20px',
      //   marginEnd: '10px',
      // }}
      />
    ),
  },
  {
    title: 'Logout',
    icon: (
      <svg className="me-3 menu-logout" width="30" height="30" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M16.8302 6.08667C17.9837 7.24056 18.7692 8.71057 19.0873 10.3108C19.4054 11.9111 19.2419 13.5698 18.6174 15.0771C17.9929 16.5844 16.9354 17.8728 15.5788 18.7792C14.2221 19.6856 12.6272 20.1693 10.9956 20.1693C9.36403 20.1693 7.76908 19.6856 6.41243 18.7792C5.05579 17.8728 3.99836 16.5844 3.37386 15.0771C2.74936 13.5698 2.58582 11.9111 2.90393 10.3108C3.22204 8.71057 4.00751 7.24056 5.16103 6.08667"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M11 1.83331V11" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    ),
  },
  // {
  //   title: 'Market Value',
  //   path: 'market-value',
  // },
  // {
  //   title: 'Subscription History',
  //   path: 'subscription/history',
  // },
  // {
  //   title: 'Query',
  //   path: 'end-of-year/pending',
  //   icon: <PaperClipOutlined />,
  // },
];

export default NavigationConfig;
