import { postRequest } from '../../../utils/apiUtils';

export const getNotRegisteredUsers = (body) => {
  return new Promise(async (resolve, reject) => {
    const { result, error } = await postRequest(`/admin/not-registered-users`, body);
    const data = result.result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};
