import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { saveNotification, getNotificationList, deleteNotification } from '../../../actions/Notification/NotificationApi';

const initialState = {
  error: false,
  isLoading: true,
  notificationList: [],
};

export const getNotificationListAsync = createAsyncThunk('Notification/getNotificationList', async () => {
  const response = await getNotificationList();
  return response;
});

export const saveNotificationAsync = createAsyncThunk('Notification/saveNotification', async (body) => {
  const response = await saveNotification(body);
  return response;
});

export const deleteNotificationAsync = createAsyncThunk('Notification/deleteNotification', async (id) => {
  const response = await deleteNotification(id);
  return response;
});

export const NotificationSlice = createSlice({
  name: 'Notification',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(saveNotificationAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(saveNotificationAsync.fulfilled, (state, action) => {
        state.error = action.payload.error;
        state.isLoading = false;
      })
      .addCase(saveNotificationAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
      })
      .addCase(getNotificationListAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getNotificationListAsync.fulfilled, (state, action) => {
        state.notificationList = action.payload.data;
        state.error = action.payload.error;
        state.isLoading = false;
      })
      .addCase(getNotificationListAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
      })
      .addCase(deleteNotificationAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteNotificationAsync.fulfilled, (state, action) => {
        state.error = action.payload.error;
        state.isLoading = false;
      })
      .addCase(deleteNotificationAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
      });
  },
});

export const selectNotificationList = (state) => state.notification.notificationList;
export const selectNotificationLoading = (state) => state.notification.isLoading;

export default NotificationSlice.reducer;
