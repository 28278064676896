import { DatePicker, Form, Input, Table, notification, Button, Popconfirm, Tag, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import { DeleteOutlined, ReloadOutlined } from '@ant-design/icons';
import { changeCouponStatusAsync, deleteUserCouponAsync, fetchUserCouponAsync, selectUserCoupon, selectUserCouponLoading } from '../../container/reducers/slices/UserCoupon/UserCouponsSlice';
import AddCoupoun from './AddCoupoun';
import editIcon from '../../assets/images/edit (1).svg';
import { CustomIcon } from '../../assets/icons';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { UserStatus } from '../../components/common/userTypes';
dayjs.extend(isSameOrAfter);
const { RangePicker } = DatePicker;

const Coupon = () => {
  const [filterForm] = Form.useForm();
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({
    apply_from: null,
    apply_to: null,
    valid_from: null,
    valid_to: null,
    endDate: '',
    searchSlug: '',
  });

  const [open, setOpen] = useState(false);
  const [record, setRecord] = useState(null);
  const data = useSelector(selectUserCoupon);
  const loading = useSelector(selectUserCouponLoading);

  useEffect(() => {
    dispatch(fetchUserCouponAsync({ ...filter }));
  }, [filter, dispatch]);

  const SearchSlug = debounce((e) => {
    const values = e.target.value.trim();
    setFilter({ ...filter, searchSlug: values });
  }, 300);

  const handleDelete = async (id) => {
    try {
      const res = await dispatch(deleteUserCouponAsync(id));
      if (res?.payload?.data?.success === true) {
        notification.success({
          message: 'Success',
          description: res?.payload?.data?.message,
        });
        await dispatch(fetchUserCouponAsync());
      } else {
        notification.error({
          message: 'Failure',
          description: res?.payload?.data?.message || 'Something went wrong!',
        });
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.message || 'Something went wrong!',
      });
    }
  };

  const handleEditCoupon = (record) => {
    setRecord(record);
    setOpen(true);
  };

  const handleCouponState = async (checked, record) => {
    let active = true;
    if (record.is_active === UserStatus.ACTIVE) {
      active = UserStatus.DELETED;
    } else {
      active = UserStatus.ACTIVE;
    }

    try {
      const res = await dispatch(changeCouponStatusAsync({ id: record.id, info: { is_active: active } }));
      if (res?.payload?.data?.success === true) {
        notification.success({
          message: 'Success',
          description: res?.payload?.data?.message,
        });
        await dispatch(fetchUserCouponAsync());
      } else {
        notification.error({
          message: 'Failure',
          description: res?.payload?.data?.message || 'Something went wrong!',
        });
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.message || 'Something went wrong!',
      });
    }
  };

  const columns = [
    {
      title: 'Coupon Code',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Apply From',
      dataIndex: 'apply_from',
      key: 'apply_from',
    },
    {
      title: 'Apply To',
      dataIndex: 'apply_to',
      key: 'apply_to',
    },
    {
      title: 'Valid From',
      dataIndex: 'valid_from',
      key: 'valid_from',
    },
    {
      title: 'Valid To',
      dataIndex: 'valid_to',
      key: 'valid_to',
    },

    {
      title: 'Discount %',
      dataIndex: 'discount_percentage',
      key: 'discount_percentage',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => {
        return (
          <Switch
            checkedChildren="Active"
            unCheckedChildren="Inactive"
            disabled={dayjs().isSameOrAfter(record.apply_from, 'day')}
            checked={record.is_active === UserStatus.ACTIVE}
            onChange={(checked) => handleCouponState(checked, record)}
          />
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (text, record) => {
        return dayjs().isSameOrAfter(record.apply_from, 'day') ? (
          '-'
        ) : (
          <div className="d-flex gap-3">
            <Button icon={<CustomIcon icon={editIcon} />} onClick={() => handleEditCoupon(record)} type="primary" size="large" />
            <Popconfirm title="Are you sure you want to delete?" placement="top" onConfirm={() => handleDelete(record.id)} okText="Yes" cancelText="No">
              <Button
                className="Delete_btn"
                size="large"
                icon={
                  <DeleteOutlined
                    style={{
                      fontSize: 18,
                      paddingTop: 3,
                    }}
                  />
                }
                type="primary"
              />
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const resetFilter = () => {
    filterForm.resetFields();
    setFilter({
      startDate: '',
      endDate: '',
      searchSlug: '',
    });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <div className="d-flex gap-3 align-items-center flex-sm-column loans-header-responsive flex-md-row flex-lg-row  justify-content-between">
        <div>
          <div className="text-center text-sm-start mb-sm-0 mb-3 mb-sm-0">
            <h5 className="m-0">Coupons</h5>
          </div>
        </div>
        <div className="date_picker reset-width-100">
          <Form form={filterForm}>
            <div className="d-flex  loans-header-responsive justify-content-between align-items-center gap-3">
              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0">
                  <Form.Item name="Search" className="p-1 m-0">
                    <Input onChange={(e) => SearchSlug(e)} style={{ width: '100%', background: 'transparent' }} placeholder="Search" />
                  </Form.Item>
                </div>
              </div>
              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0">
                  <Form.Item name="applyRangePicker" className="p-0 m-0">
                    <RangePicker
                      placeholder={['Apply From', 'Apply To']}
                      onChange={(values) => {
                        const [apply_from, apply_to] = values;
                        setFilter({ ...filter, apply_from: apply_from, apply_to: apply_to });
                      }}
                      onCalendarChange={(values) => {
                        const [apply_from, apply_to] = values;
                        setFilter({ ...filter, apply_from: apply_from, apply_to: apply_to });
                      }}
                      format={['DD-MM-YYYY', 'DD-MM-YYYY']}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0">
                  <Form.Item name="validRangePicker" className="p-0 m-0">
                    <RangePicker
                      placeholder={['Valid From', 'Valid To']}
                      onChange={(values) => {
                        const [valid_from, valid_to] = values;
                        setFilter({ ...filter, valid_from: valid_from, valid_to: valid_to });
                      }}
                      onCalendarChange={(values) => {
                        const [valid_from, valid_to] = values;
                        setFilter({ ...filter, valid_from: valid_from, valid_to: valid_to });
                      }}
                      format={['DD-MM-YYYY', 'DD-MM-YYYY']}
                    />
                  </Form.Item>
                </div>
              </div>

              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0 ">
                  <Button icon={<ReloadOutlined />} type="primary" size="large" className="reset-width-100 d-flex align-items-center" onClick={() => resetFilter()}>
                    Reset
                  </Button>
                </div>
              </div>
              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0 ">
                  <Button type="primary" size="large" className="reset-width-100 d-flex align-items-center" onClick={handleOpen}>
                    + Add
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <div className="dashboard_list">
            <Table columns={columns} pagination={{ showSizeChanger: true }} dataSource={data?.result} loading={loading} />
          </div>
        </div>
      </div>
      <AddCoupoun open={open} setOpen={setOpen} record={record} setRecord={setRecord} />
    </>
  );
};

export default Coupon;
