import { deleteRequest, getRequest, postRequest } from '../../../utils/apiUtils';

export const saveNotification = (body) => {
  return new Promise(async (resolve, reject) => {
    const { result, error } = await postRequest('/admin/schedule', body);
    const data = result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};

export const getNotificationList = () => {
  return new Promise(async (resolve, reject) => {
    const { result, error } = await getRequest('/admin/schedule');
    const data = result.result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};

export const deleteNotification = (id) => {
  return new Promise(async (resolve, reject) => {
    const { result, error } = await deleteRequest(`/admin/schedule/${id}`);
    const data = result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};
