import { Button, DatePicker, Form, Input, notification, Spin, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { GetChannelPartnerList } from '../../container/actions/ChannelPartner/getChannelPartner.action';
import 'react-phone-input-2/lib/style.css';
import { debounce } from 'lodash';
import { ReloadOutlined } from '@ant-design/icons';
import { ChannelPartnerTypeOption } from '../../utils';
import ModalAddChannelPartner from '../../utils/ModalAddChannelPartner';

const ChannelPartner = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filterForm] = Form.useForm();
  const [filter, setFilter] = useState({
    startDate: '',
    endDate: '',
    searchSlug: '',
  });

  const [Loading, setLoading] = useState(false);
  const [taxAccountData, setTaxAccountData] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    dispatch(GetChannelPartnerList(filter)).then((res) => {
      if (res?.payload?.success === true) {
        setTaxAccountData(res.payload.result);
        setLoading(false);
      }
    });
  }, [filter, dispatch]);

  const startDateChange = (value) => {
    if (value && dayjs(value).isValid && dayjs(filter.endDate) < dayjs(value)) {
      notification.error({
        message: 'Failure',
        description: 'Start Date should be greter then End Date.',
      });
      filterForm.setFieldsValue({ startDate: '' });
      return;
    }
    setFilter({ ...filter, startDate: value && value !== null ? dayjs(value).format('YYYY-MM-DD') : '' });
  };

  const endDateChange = (value) => {
    if (value && dayjs(value).isValid && dayjs(filter.startDate) > dayjs(value)) {
      notification.error({
        message: 'Failure',
        description: 'Start Date should be greter then End Date.',
      });
      filterForm.setFieldsValue({ endDate: '' });
      return;
    }
    setFilter({ ...filter, endDate: value && value !== null ? dayjs(value).format('YYYY-MM-DD') : '' });
  };

  const SearchSlug = debounce((e) => {
    const values = e.target.value.trim();
    setFilter({ ...filter, searchSlug: values });
  }, 300);

  const TaxAccountantColumn = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
    },
    {
      title: 'User Email',
      dataIndex: 'email',
      key: 'email',
      fixed: 'left',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone_number',
      key: 'phone_number',
      render: (text) => text || '-',
    },
    {
      title: 'Company Name',
      dataIndex: 'company_name',
      key: 'company_name',
      render: (_, record) => {
        return record?.company_name ? record.company_name : '-';
      },
    },
    {
      title: 'Type',
      dataIndex: 'channel_partner_type',
      key: 'channel_partner_type',
      render: (text) => {
        return ChannelPartnerTypeOption.find((item) => item.value === text)?.label || '-';
      },
    },
    {
      title: 'Total Clients',
      dataIndex: 'client_count',
      key: 'client_count',
      render: (_, record) => {
        return record?.client_count ? record.client_count : '0';
      },
    },
    {
      title: 'Referral Code',
      dataIndex: 'referral_code',
      key: 'referral_code',
      render: (text) => {
        return text || '-';
      },
    },
  ];

  const onSuccessAdd = () => {
    setFilter({ ...filter });
  };

  const resetFilter = () => {
    filterForm.resetFields();
    setFilter({
      startDate: '',
      endDate: '',
      searchSlug: '',
    });
  };

  return (
    <>
      <div className="d-flex gap-3 align-items-center flex-sm-column loans-header-responsive flex-md-row flex-lg-row  justify-content-between">
        <div>
          <div className="text-center text-sm-start mb-sm-0 mb-3 mb-sm-0">
            <h5 className="m-0">Channel Partner</h5>
          </div>
        </div>
        <div className="reset-width-100 ">
          <Form form={filterForm}>
            <div className="d-flex loans-header-responsive align-items-center gap-3 justify-content-end">
              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0">
                  <Form.Item name="Search" className="p-1 m-0">
                    <Input onChange={(e) => SearchSlug(e)} allowClear style={{ width: '100%', background: 'transparent' }} placeholder="Search" />
                  </Form.Item>
                </div>
              </div>
              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0">
                  <Form.Item name="startDate" className="p-0 m-0">
                    <DatePicker format="DD-MM-YYYY" onChange={startDateChange} style={{ width: '100%', background: 'transparent' }} placeholder="Start Date" />
                  </Form.Item>
                </div>
              </div>

              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0 ">
                  <Form.Item name="endDate" className="p-0 m-0">
                    <DatePicker format="DD-MM-YYYY" onChange={endDateChange} style={{ width: '100%', background: 'transparent' }} placeholder="End Date" />
                  </Form.Item>
                </div>
              </div>

              <div className="reset-width-100">
                <div className="mb-3 d-flex gap-3  mb-lg-0 ">
                  <Button icon={<ReloadOutlined />} type="primary" size="large" className="reset-width-100 d-flex align-items-center" onClick={() => resetFilter()}>
                    Reset
                  </Button>

                  <Button size="large" htmlType="submit" type="primary" className="reset-width-100" onClick={() => setIsModalOpen(true)}>
                    + Add New
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
      <Spin spinning={Loading}>
        <div className="row mt-4">
          <div className="col-12">
            <div className="dashboard_list">
              <Table columns={TaxAccountantColumn} dataSource={taxAccountData} rowKey={'id'} scroll={{ x: 'max-content' }} />
            </div>
          </div>
        </div>
      </Spin>

      {/* ADD MODAL  */}
      <ModalAddChannelPartner isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} onSuccessAdd={onSuccessAdd} channelPartner={true} />
    </>
  );
};

export default ChannelPartner;
