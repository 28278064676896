import { Button, DatePicker, Form, Input, notification, Table } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PencilIcon } from '../../assets/icons';
import { GetAllValuations } from '../../container/actions/valuations/getAllValuations.action';
import { formatNumberToCurrency, isValidObject } from '../../utils/utilities';
import EditValuation from './Models/EditValuation';
import { getPropertyTypeLabel } from '../../utils/ConstLabel';
import { debounce } from 'lodash';
import { ReloadOutlined } from '@ant-design/icons';

const Valuation = () => {
  const dispatch = useDispatch();
  const [filterForm] = Form.useForm();
  const [allValuations, setAllValuations] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [filter, setFilter] = useState({
    from_date: '',
    to_date: '',
    searchSlug: '',
  });
  const { allValuationsData, loadingAllValuations } = useSelector((state) => state.getAllValuationReducer);

  useEffect(() => {
    const storedFilter = localStorage.getItem('valuationFilter');
    setFilter(storedFilter || filter);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(GetAllValuations(filter));
  }, [filter, dispatch]);

  useEffect(() => {
    if (isValidObject(allValuationsData)) {
      setAllValuations(allValuationsData?.result);
    }
  }, [allValuationsData]);

  const handleModal = (record, callback) => {
    setModalData([record]);
    callback();
  };
  const SearchSlug = debounce((e) => {
    const values = e.target.value.trim();
    setFilter({ ...filter, searchSlug: values });
  }, 300);

  const startDateChange = (value) => {
    if (value && dayjs(value).isValid && dayjs(filter.to_date) < dayjs(value)) {
      notification.error({
        message: 'Failure',
        description: 'Start Date should be greter then End Date.',
      });
      filterForm.setFieldsValue({ startDate: '' });
      return;
    }
    setFilter({ ...filter, from_date: value && value !== null ? dayjs(value).format('YYYY-MM-DD') : '' });
  };

  const endDateChange = (value) => {
    if (value && dayjs(value).isValid && dayjs(filter.from_date) > dayjs(value)) {
      notification.error({
        message: 'Failure',
        description: 'Start Date should be greter then End Date.',
      });
      filterForm.setFieldsValue({ endDate: '' });
      return;
    }
    setFilter({ ...filter, to_date: value && value !== null ? dayjs(value).format('YYYY-MM-DD') : '' });
  };

  const tableColumns = [
    {
      title: 'Property Address',
      dataIndex: 'address',
      key: 'address',
      width: '438px',
      render: (_, record) => {
        const { street_number, street_name, suburb, state, postcode } = record;
        return <>{street_number + ' - ' + street_name + ' ' + suburb + ' ' + state + ' ' + postcode}</>;
      },
    },
    {
      title: 'Property Type',
      dataIndex: 'property_type',
      key: 'property_type',
      sorter: (a, b) => a.property_type - b.property_type,
      render: (text) => {
        return getPropertyTypeLabel(text);
      },
    },
    {
      title: 'User Market Value',
      dataIndex: 'market_value',
      key: 'market_value',
      sorter: (a, b) => a.market_value - b.market_value,
      render: (_, record) => {
        const { market_value = '-' } = record;
        return <>{market_value ? formatNumberToCurrency(market_value) : '-'}</>;
      },
    },
    {
      title: 'Admin Market Value',
      dataIndex: 'admin_market_value',
      key: 'admin_market_value',
      sorter: (a, b) => a.admin_market_value - b.admin_market_value,
      render: (_, record) => {
        const { admin_market_value = '-' } = record;
        return <>{admin_market_value ? formatNumberToCurrency(admin_market_value) : '-'}</>;
      },
    },
    {
      title: 'Date Updated',
      dataIndex: 'updated_date',
      key: 'updated_date',
      sorter: (a, b) => new Date(dayjs(a?.updated_at)) - new Date(dayjs(b?.updated_at)),
      render: (_, record) => {
        return <>{record?.mv_updated_at === null ? dayjs(record?.updated_at).format('DD-MM-YYYY') : dayjs(record?.mv_updated_at).format('DD-MM-YYYY')}</>;
      },
    },
    {
      title: 'Edit',
      dataIndex: 'property',
      key: 'property',
      render: (_, record) => {
        return (
          <button className="btn btn-primary mt-xxl-0 mt-2 property_edit" onClick={() => handleModal(record, () => setModalOpen(true))}>
            <PencilIcon />
          </button>
        );
      },
    },
  ];

  const resetFilter = () => {
    filterForm.resetFields();
    setFilter({ from_date: '', to_date: '', searchSlug: '' });
  };

  return (
    <>
      <div className="d-flex gap-3 flex-sm-column flex-md-row flex-lg-row loans-header-responsive justify-content-between align-items-center">
        <div>
          <div className="text-center text-sm-start mb-sm-0 mb-3 mb-sm-0">
            <h5 className="m-0">Data Entry Form</h5>
          </div>
        </div>

        <div className="date_picker reset-width-100">
          <Form form={filterForm}>
            <div className="d-flex justify-content-around loans-header-responsive gap-3 align-items-center">
              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0">
                  <Form.Item name="Search" className="p-0 m-0">
                    <Input onChange={(e) => SearchSlug(e)} allowClear style={{ width: '100%', background: 'transparent' }} placeholder="Search" />
                  </Form.Item>
                </div>
              </div>
              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0">
                  <Form.Item name="startDate" className="p-0 m-0">
                    <DatePicker format="DD-MM-YYYY" onChange={startDateChange} style={{ width: '100%', background: 'transparent' }} placeholder="Start Date" />
                  </Form.Item>
                </div>
              </div>

              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0">
                  <Form.Item name="endDate" className="p-0 m-0">
                    <DatePicker format="DD-MM-YYYY" onChange={endDateChange} style={{ width: '100%', background: 'transparent' }} placeholder="End Date" />
                  </Form.Item>
                </div>
              </div>
              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0 reset-width-100">
                  <Button type="primary" icon={<ReloadOutlined />} size="large" className="reset-width-100 d-flex align-items-center" onClick={() => resetFilter()}>
                    Reset
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-12">
          <div className="dashboard_list">
            <Table columns={tableColumns} dataSource={allValuations} loading={loadingAllValuations} />
          </div>
        </div>
      </div>

      {isModalOpen && <EditValuation isModalOpen={isModalOpen} closeModal={() => setModalOpen(false)} data={modalData} />}
    </>
  );
};

export default Valuation;
