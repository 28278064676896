import { Card, DatePicker, Empty, Spin, Statistic, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { ChannelPartnerType, UserTypes } from '../../components/common/userTypes';
import './dashboard.css';
import { formatNumber } from '../../utils/utilities';
import {
  getDashboardDataAsync,
  getDashboardUserDataAsync,
  selectDashboardData,
  selectDashboardDataLoading,
  selectDashboardUserData,
  selectDashboardUserDataLoading,
} from '../../container/reducers/slices/Dashboard/DashboardSlice';
import dayjs from 'dayjs';
const { RangePicker } = DatePicker;

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userFilter, setUserFilter] = useState({ startDate: null, endDate: null });
  const [columnTitle, setColumnTitle] = useState('Month-Year');

  useEffect(() => {
    dispatch(getDashboardDataAsync());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getDashboardUserDataAsync(userFilter));
  }, [dispatch, userFilter]);

  const dashboardData = useSelector(selectDashboardData);
  const dashboardUserData = useSelector(selectDashboardUserData);

  const dashboardDataLoading = useSelector(selectDashboardDataLoading);
  const dashboardUserDataLoading = useSelector(selectDashboardUserDataLoading);

  // const handleEmailReview = (data) => {
  //   navigate('/dashboard/junk-emails', { state: data });
  // };

  useEffect(() => {
    // Check if any record has the `range` field
    const hasRange = dashboardUserData?.userAndPropertyCount?.some((record) => record.range);
    if (hasRange) {
      setColumnTitle('Date Range');
    } else {
      setColumnTitle('Month-Year');
    }
  }, [dashboardUserData?.userAndPropertyCount]);

  const tableColumns = [
    {
      title: columnTitle,
      dataIndex: 'month',
      key: 'month',
      width: '350px',
      render: (_, record) => {
        if (record.month) {
          let month1 = record.month.split('-');
          const monthString = new Date(2000, month1[0] - 1).toLocaleString('default', { month: 'long' });
          return `${monthString} - ${month1[1]}`;
        } else {
          const [start, end] = record.range.split(' - ');

          const formattedStart = dayjs(start).format('YYYY-MM-DD');
          const formattedEnd = dayjs(end).format('YYYY-MM-DD');

          const formattedRange = `${formattedStart} - ${formattedEnd}`;
          return formattedRange;
        }
      },
    },
    {
      title: 'Users',
      dataIndex: 'userCount',
      key: 'userCount',
      width: '350px',
      render: (text) => text || 0,
    },
    {
      title: 'Properties',
      dataIndex: 'propertyCount',
      key: 'propertyCount',
      width: '350px',
      render: (text) => text || 0,
    },
    {
      title: 'Entries',
      dataIndex: 'transactionCount',
      key: 'transactionCount',
      width: '350px',
      render: (text) => text || 0,
    },
  ];
  const pageSize = 4;

  const statsData = [
    {
      title: 'User',
      value: dashboardData?.userData?.find((item) => item.user_type === UserTypes.USER)?.count,
      link: '/user/property-investor',
    },
    {
      title: 'Tax Accountant',
      value: dashboardData?.userData?.find((item) => item.user_type === UserTypes.TAXACCOUTANT)?.count,
      link: '/user/tax-accountant',
    },
    {
      title: 'Mortgage Broker',
      value: dashboardData?.userData?.find((item) => item.user_type === UserTypes.CHANNEL_PARTNER && item.channel_partner_type === ChannelPartnerType.MORTGAGE_BROKER)?.count,
      link: '/user/mortgage-broker',
    },
    {
      title: 'Channel Partner',
      value: dashboardData?.userData?.find((item) => item.user_type === UserTypes.CHANNEL_PARTNER && item.channel_partner_type !== ChannelPartnerType.MORTGAGE_BROKER)?.count,
      link: '/user/channel-partner',
    },
    {
      title: 'Total Properties',
      value: dashboardData?.propertyData?.count,
    },
    {
      title: 'Total Entries',
      value: dashboardData?.transactionCount,
    },
    {
      title: 'Property Valuation',
      value: formatNumber(dashboardData?.propertyData?.propertyValuation || 0),
      link: '/property-valuation',
    },
    {
      title: 'Transaction Value',
      value: formatNumber(dashboardData?.transactionValue || 0),
    },
    {
      title: 'Android Downloads',
      value: dashboardData?.androidAppDownloads,
    },
    {
      title: 'iOS Downloads',
      value: dashboardData?.iOSAppDownloads,
    },
    {
      title: 'Total Revenue',
      value: formatNumber(dashboardData?.totalRevenue || 0),
    },
    {
      title: 'Managed Clients',
      value: dashboardData?.managedClients || 0,
      link: '/user/managed-users',
    },
    {
      title: 'Onboard Pending',
      value: dashboardData?.onboardPending || 0,
    },
    {
      title: 'Onboard Completed',
      value: dashboardData?.onboardCompleted || 0,
    },
    {
      title: 'Not Registered',
      value: dashboardData?.notRegisteredUsers || 0,
      link: '/user/not-registered-users',
    },
  ];

  const cardStatsData = [
    {
      title: 'Depreciation',
      pending: dashboardData?.deprecationData?.pending,
      complete: dashboardData?.deprecationData?.complete,
    },
    {
      title: 'Settlement',
      pending: dashboardData?.settlementData?.pending,
      complete: dashboardData?.settlementData?.complete,
    },
    {
      title: 'Rent Summary',
      pending: dashboardData?.rentData?.pending,
      complete: dashboardData?.rentData?.complete,
    },
    {
      title: 'Property Valuation',
      pending: dashboardData?.marketData?.pending,
      complete: dashboardData?.marketData?.complete,
    },
    {
      title: 'EOFY',
      pending: dashboardData?.eofyData?.pending || 0,
      complete: dashboardData?.eofyData?.complete || 0,
    },
  ];

  const handleDateFilter = (date, dateString) => {
    if (date === null) {
      setUserFilter({ startDate: null, endDate: null });
      return;
    }
    setUserFilter({ startDate: date[0], endDate: date[1] });
  };

  return (
    <Spin spinning={dashboardDataLoading}>
      <div className="row align-items-center">
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-5 row-cols-xl-5 g-4 mt-0 dashboardStatsParent card-group">
          {statsData.map((item, i) => {
            return (
              <Link to={item?.link} key={i}>
                <div className="col">
                  <Statistic className="card h-100 p-4 pb-2 pt-3" title={item.title} value={item.value || 0} />
                </div>
              </Link>
            );
          })}
        </div>

        <div className="col-12">
          <div className="text-center d-flex justify-content-between text-sm-start mt-4 mb-sm-0 mb-3 mb-sm-0">
            <div>
              <h5 className="m-0">Users and Properties</h5>
            </div>
            <div>
              <RangePicker allowClear onChange={handleDateFilter} />
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-12">
          <div className="dashboard_list">
            <Table columns={tableColumns} dataSource={dashboardUserData?.userAndPropertyCount || []} loading={dashboardUserDataLoading} rowKey="month" pagination={{ pageSize }} />
          </div>
        </div>
      </div>

      <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-5   g-3 dashboard_wrapper">
        {cardStatsData.map((item, i) => {
          return (
            <div className="col">
              <Card title={<h6 className="mb-0">{item.title}</h6>} bordered={false}>
                <div className="d-flex justify-content-between mb-2 align-items-center d-flex">
                  <h6 className="text-muted fw-normal m-0">Pending</h6>
                  <h5 className="card-title fw-bold">{item.pending}</h5>
                </div>
                <div className="d-flex justify-content-between mb-2 align-items-center d-flex">
                  <h6 className="text-muted fw-normal m-0">Completed</h6>
                  <h5 className="card-title fw-bold">{item.complete}</h5>
                </div>
              </Card>
            </div>
          );
        })}
      </div>

      <div className="row dashboard_wrapper">
        <div className="col-md-6 col-12  mt-4">
          <div className="d-flex justify-content-between">
            <h5>User Email</h5>
            <a onClick={() => navigate('/user/property-investor')}>
              <span className="Route_showmore"> Show more </span>
            </a>
          </div>
          <div className=" card1">
            <ul className="list-group list-group-flush">
              {dashboardData?.newUserData?.length > 0 ? (
                dashboardData?.newUserData?.map((item, i) => {
                  return (
                    <li className="list-group-item">
                      <div className="d-flex align-items-center justify-content-between dashboard_unique_email">
                        <div>
                          <p className="unique_email mb-0">{item?.email}</p>
                        </div>
                      </div>
                    </li>
                  );
                })
              ) : (
                <li className="list-group-item">
                  <div>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  </div>
                </li>
              )}
            </ul>
          </div>
        </div>
        <div className="col-md-6 col-12  mt-4">
          <div className="d-flex justify-content-between">
            <h5>TaxAccountant Email</h5>
            <a onClick={() => navigate('/user/tax-accountant', { state: { flag: 'tax' } })}>
              <span className="Route_showmore"> Show more </span>
            </a>
          </div>
          <div className=" card1">
            <ul className="list-group list-group-flush">
              {dashboardData?.newTaxAccountantData?.length > 0 ? (
                dashboardData?.newTaxAccountantData?.map((item, i) => {
                  return (
                    <li className="list-group-item">
                      <div className="d-flex align-items-center justify-content-between dashboard_unique_email">
                        <div>
                          <p className="unique_email mb-0">{item?.email}</p>
                        </div>
                      </div>
                    </li>
                  );
                })
              ) : (
                <li className="list-group-item">
                  <div>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  </div>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>

      {/* <div className="row mt-4 dashboard_wrapper">
          <div className="col-12">
            <h5>Junk Emails</h5>
            <div className="card">
              <ul className="list-group list-group-flush">
                {dashData?.diverseMailData?.length > 0 ? (
                  dashData?.diverseMailData?.map((item, i) => {
                    return (
                      <Fragment>
                        <li className="list-group-item">
                          <div className="d-flex align-items-center justify-content-between">
                            <div>
                              <p className="unique_email m-0">{item?.email}</p>
                            </div>
                            <div className="depreciation_dashboard" onClick={() => handleEmailReview(item) }>
                              <p className="m-0">Review</p>
                            </div>
                          </div>
                        </li>
                      </Fragment>
                    );
                  })
                ) : (
                  <Fragment>
                    <li className="list-group-item">
                    <div>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      </div>
                    </li>
                  </Fragment>
                )}
              </ul>
            </div>
          </div>
        </div> */}
    </Spin>
  );
};

export default Dashboard;
